import { LOAN_PROVIDER } from "../enums/loanProvider.enum";
import { removeUndefineds } from "./common.function.utils";
import { getLoanProviderName } from "./finance.utils";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
interface EventDataInterface {
  event: string; // Required: Name of the event
  leadId?: string; // Optional: User ID
  data: any;
  [key: string]: any; // Additional properties
}

export const pushToDataLayer = (eventData: EventDataInterface) => {
  console.log("eventData");
  console.log(eventData);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventData);
};

export const getPanelDataForGTM = (panel: any) => {
  try {
    if (!panel) return null;
    const {
      madein,
      manufacturer: { manufacturer },
      panel_name,
      specifications: { warrenty },
      is_recommended,
      panel_prices,
    } = panel;
    const { price_per_watt } = panel_prices[0];
    const data = {
      madein,
      manufacturer,
      panel_name,
      warrenty,
      is_recommended,
      price_per_watt,
    };
    return { panel: data };
  } catch (error) {
    console.error("Error in getPanelDataForGTM:", error);
    return null;
  }
};

export const getBatteryDataForGTM = (battery: any) => {
  try {
    if (!battery) return null;
    const {
      id,
      battery_name,
      battery_prices,
      qty,
      manufacturer: { manufacturer },
      madein,
      specifications: { warrenty, energy_capacity },
    } = battery;
    const { price } = battery_prices[0];
    const batteryData = {
      id,
      battery_name,
      manufacturer,
      qty,
      price,
      madein,
      warrenty,
      energy_capacity,
    };
    return { battery: batteryData };
  } catch (error) {
    console.error("Error in getBatteryDataForGTM:", error);
    return null;
  }
};

export const getHosueHoldDataForGTM = (homeAppliances: any) => {
  try {
    if (!homeAppliances) return null;
    const selectedHouseHolds = homeAppliances
      ?.filter((data: any) => data?.is_selected)
      .map((data: any) => {
        const { name, kwh, qty } = data;
        return { name, kwh, qty };
      });
    return { houseHolds: selectedHouseHolds };
  } catch (error) {
    console.error("Error in getHosueHoldDataForGTM:", error);
    return null;
  }
};

export const getInverterDataForGTM = (inverter: any) => {
  try {
    if (!inverter) return null;
    const {
      inverter_type,
      madein,
      name,
      manufacturer: { name: manufacturer },
      module_power,
      warranty,
    } = inverter;
    const inverterData = {
      inverter: {
        name,
        inverter_type,
        madein,
        manufacturer,
        module_power,
        warranty,
      },
    };
    return inverterData;
  } catch (error) {
    console.error("Error in getInverterDataForGTM:", error);
    return null;
  }
};

export const getProposalDataForGTM = (lead: any) => {
  try {
    const { proposal } = lead;
    const { module, house_hold_appliances, battery, inverter } = proposal;

    const data = {
      panel: getPanelDataForGTM(module)?.panel,
      battery: getBatteryDataForGTM(battery)?.battery,
      house_hold_appliances: getHosueHoldDataForGTM(house_hold_appliances)
        ?.houseHolds,
      inverter: getInverterDataForGTM(inverter)?.inverter,
      orders_summary: getOrderSummaryForGTM(proposal),
      lead: getLeadDataForGTM(lead)?.lead,
      design: getDesignDataForGTM(proposal?.design_info)?.design,
    };
    return data;
  } catch (error) {
    console.error("Error in getProposalDataForGTM:", error);
    return null;
  }
};

export const getOrderSummaryForGTM = (proposal: any) => {
  try {
    const {
      adders_total,
      discounts,
      gross_total,
      net_amount,
      discounts_total,
      selected_adders,
      avg_monthly_bill,
      annual_usage,
      base_system_total,
      battery_total,
      fed_tax_credit,
      rec_price,
      contract_type,
    } = proposal;
    const adders =
      selected_adders &&
      selected_adders?.map((current: any) => {
        const { title, total_price } = current;
        return {
          name: title,
          amount: total_price,
        };
      });
    const dis =
      discounts &&
      discounts?.map((current: any) => {
        const { discount_name, discount_amount } = current;
        return {
          name: discount_name,
          amount: discount_amount,
        };
      });

    const data = {
      panel_total: base_system_total,
      battery_total,
      annual_usage,
      avg_monthly_bill,
      adders_total,
      gross_total,
      net_amount,
      discounts_total,
      adders,
      discounts: dis,
      fed_tax_credit,
      rec_Value: rec_price,
      contract_type,
    };
    return data;
  } catch (error) {
    console.error("Error in getOrderSummaryForGTM:", error);
    return null;
  }
};

export const getLeadDataForGTM = (lead: any) => {
  try {
    const {
      first_name,
      last_name,
      city,
      email,
      phone,
      full_address,
      state,
      state_code,
      street,
      zip,
      lat,
      lng,
      co_signer_email,
      co_signer_name,
      from_url,
    } = lead;
    const data = {
      first_name,
      last_name,
      city,
      email,
      phone,
      full_address,
      state,
      state_code,
      street,
      zip,
      lat,
      lng,
      co_signer_email,
      co_signer_name,
      from_url,
    };
    return { lead: removeUndefineds(data) };
  } catch (error) {
    console.error("Error in getLeadDataForGTM:", error);
    return null;
  }
};

export const getDesignDataForGTM = (design_info: any) => {
  try {
    const {
      total_panels,
      totalPanels,
      system_size,
      systemSize,
      offset,
      annual_production,
      yearlyEnergy,
      img,
      lat,
      lng,
      billAmount,
    } = design_info;
    const design = removeUndefineds({
      total_panels: total_panels || totalPanels,
      system_size: system_size || systemSize,
      offset,
      annual_production: annual_production || yearlyEnergy,
      img,
      lat,
      lng,
      monthly_bill: billAmount,
    });
    return {
      design,
    };
  } catch (error) {
    console.error("Error in getDesignDataForGTM:", error);
    return null;
  }
};

export const getQuoteDataForGTM = (loan: any) => {
  try {
    if (!loan) return null;
    const loan_provider = getLoanProviderName(loan?.loan_provider);
    const detail = getDataBasedOnLoanProvider(loan);
    return { contract: { loan_provider, detail } };
  } catch (err) {
    console.log("Error in getLoanQuoteDataForGTM", err);
  }
};

const getDataBasedOnLoanProvider = (loan: any) => {
  try {
    const { loan_provider } = loan || {};
    let func: null | Function = null;
    switch (Number(loan_provider)) {
      case LOAN_PROVIDER.GOODLEAP:
        func = getGoodLeapData;
        break;
      case LOAN_PROVIDER.MOSAIC:
        func = getMosaicData;
        break;
      case LOAN_PROVIDER.SUNLIGHT:
        func = getSunligthData;
        break;
      case LOAN_PROVIDER.LIGHTREACH:
        func = getLightReachData;
        break;
      case LOAN_PROVIDER.OWNDB:
        func = getOwnBDData;
        break;
      default:
        return loan;
    }
    if (func) return func(loan);
    return loan;
  } catch (err) {
    console.log(err);
  }
};
const getGoodLeapData = (loan: any) => {
  try {
    const {
      name,
      rate,
      term,
      payments = {},
      initialPayment,
      finalMonthlyPayment,
      finalEscalatedMonthlyPayment,
    } = loan;
    const {
      loanAmount: { value: loan_amount },
    } = payments || {};
    const data = {
      apr: rate,
      term,
      loan_amount,
      monthly_payments: [
        initialPayment,
        finalMonthlyPayment,
        finalEscalatedMonthlyPayment,
      ],
      name,
    };
    return data;
  } catch (err) {
    console.log(err);
  }
};
const getMosaicData = (loan: any) => {
  try {
    const { rate, totalPeriods, name, payments = {} } = loan;
    const { finalMonthlyPayment, finalEscalatedMonthlyPayment, loanAmount } =
      payments;
    const data = {
      apr: rate,
      term: totalPeriods,
      loan_amount: loanAmount,
      monthly_payment: [
        finalMonthlyPayment,
        finalMonthlyPayment,
        finalEscalatedMonthlyPayment,
      ],
      name,
    };
    return data;
  } catch (err) {
    console.log(err);
  }
};
const getSunligthData = (loan: any) => {
  try {
    const { name, term, payments = {}, apr } = loan;
    const { monthlyPayment, loanAmount, escalatedMonthlyPayment } =
      payments;
    const data = {
      apr,
      term,
      loan_amount: loanAmount,
      monthly_payments: [
        monthlyPayment,
        monthlyPayment,
        escalatedMonthlyPayment,
      ],
      name,
    };
    return data;
  } catch (err) {
    console.log(err);
  }
};

const getOwnBDData = (loan: any) => {
  try {
    const { term, rate, monthly_payment, loan_amount, lender_name, name } =
      loan || {};
    const data = {
      term,
      apr: rate,
      monthly_payment,
      loan_amount,
      lender_name,
      name,
    };
    return data;
  } catch (err) {
    console.log("Error in getOwnBDData", err);
    return null;
  }
};
// const getLightReachData = (loan: any) => {};

export const getQulifiedQuoteDataForGTM = (loan: any) => {
  try {
    const { name, monthly_payment, rate, term, loan_amount, loan_provider } =
      loan;
    return {
      name,
      monthly_payment,
      apr: rate,
      term,
      loan_amount,
      loan_provider: getLoanProviderName(loan_provider),
    };
  } catch (err) {
    console.log(err);
  }
};

const getLightReachData = (data: any) => {
  try {
    const {
      name,
      ppwRate,
      term,
      escalationRate,
      kwhRate,
      monthly_payment,
      quote,
      systemFirstYearProductionKwh,
    } = data || {};
    const system_first_year_productionKwh =
      quote?.quote_response?.systemFirstYearProductionKwh ||
      systemFirstYearProductionKwh;
    const result = {
      name,
      term,
      solar_rate: kwhRate,
      ppwRate,
      estimated_production: system_first_year_productionKwh,
      monthly_payment,
      payment_escalator: escalationRate,
    };
    return removeUndefineds(result);
  } catch (err) {
    console.error("Erroe in getLeaseDataForGTM", err);
  }
};
