import { FINANCNE_PROVIDER_DISPLAY_NAME } from "../constants/finance.constant";
import { LOAN_PROVIDER } from "../enums/loanProvider.enum";

export const canPreQualifyLoan = (lead:any)=>{
    const {last_name,email} = lead;
    return !!(last_name && email)
}
export const canPreQualifyLease = (lead:any)=>{
    const {last_name,email,phone} = lead;
    return !!(last_name && email && phone)
}
export const canSubmitProject = (lead:any)=>{
    const {last_name,email} = lead;
    return !!(last_name && email)
}
export function getLoanProviderName(loan_provider:LOAN_PROVIDER) {
    return FINANCNE_PROVIDER_DISPLAY_NAME[loan_provider as LOAN_PROVIDER];
  }
export function isLoanProvider(loan_provider:LOAN_PROVIDER){
    switch(Number(loan_provider)){
        case LOAN_PROVIDER.GOODLEAP:
        case LOAN_PROVIDER.MOSAIC:
        case LOAN_PROVIDER.SUNLIGHT:
        case LOAN_PROVIDER.OWNDB: return true;
        default:  return false;
    }
}
export function isLeaseProvider(loan_provider:LOAN_PROVIDER){
    switch(Number(loan_provider)){
        case LOAN_PROVIDER.SUNNOVA:
        case LOAN_PROVIDER.LIGHTREACH: return true;
        default:  return false;
    }
}