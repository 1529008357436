export enum LOAN_PROVIDER{
  SUNLIGHT = 1,
  GOODLEAP = 2,
  MOSAIC=3,
  SUNNOVA = 4,
  LIGHTREACH = 5,
  OWNDB=6
}

export enum LOAN_PROVIDER_NAME {
  SUNLIGHT = "sunlight",
  GOODLEAP = "goodleap",
  SUNNOVA = "sunnova",
  MOSAIC= "mosaic",
  OWNDB="owndb"
}