interface AlertModalProps{
  close: () => void;
  buttonLabel?: string;
  content: string;
  action: () => void;
  showCloseSvg?: boolean;
}

const AlertModal = (props: AlertModalProps) => {
  const { buttonLabel = "OK", showCloseSvg = true } = props;

  return (
    <div className="mobile-number-block w-full relative ">
      {showCloseSvg &&
        <span
        className="hidden cursor-pointer w-1/6 sm:w-auto xxs:flex justify-end"
        onClick={() => {
          props.close();
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-3 right-4 h-7 w-7 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="M6 18L18 6M6 6l12 12" />
        </svg>
        </span>
      }
      <div className={`${showCloseSvg ? 'pt-16': ''} p-4 md:p-8 md:py-14 md:px-12`} >
        <h2 className="text-left mb-3 text-xl font-primaryMedium text-titleColor">
          {props.content} </h2>

        <button type="submit" onClick={() => {
          props.action()
        }} className="w-full text-center bg-primary text-white p-3 rounded mt-8 " >
          {buttonLabel}</button>

      </div>
    </div>)
}
export default AlertModal;