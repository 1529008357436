import { LOAN_PROVIDER } from "../enums/loanProvider.enum";

export const FINANCNE_PROVIDER_DISPLAY_NAME = {
  [LOAN_PROVIDER.SUNLIGHT]: "Sunlight",
  [LOAN_PROVIDER.GOODLEAP]: "Goodleap Finance",
  [LOAN_PROVIDER.SUNNOVA]: "Sunnova",
  [LOAN_PROVIDER.MOSAIC]: "Mosaic",
  [LOAN_PROVIDER.LIGHTREACH]: "LightReach",
  [LOAN_PROVIDER.OWNDB]: "Own DB"
}

export const FINANCE_PROVIDER_LOGO = {
  [LOAN_PROVIDER.SUNLIGHT]: "/images/partner/sunlight-financial-logo.png",
  [LOAN_PROVIDER.GOODLEAP]: "/images/partner/goodleap.png",
  [LOAN_PROVIDER.SUNNOVA]: "/images/sunnova-logo.png",
  [LOAN_PROVIDER.MOSAIC]: "/images/partner/mosaic.png",
  [LOAN_PROVIDER.LIGHTREACH]: "/images/partner/light-reach-proposal-logo.png",
  [LOAN_PROVIDER.OWNDB]: ""
}