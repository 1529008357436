export enum GTMCustomEvents {
    RUN_AI_DESING="RUN_AI_DESING",
    SAVE_DESIGN="SAVE_DESIGN",
    UNSUPPORTED_ADDRESS="UNSUPPORTED_ADDRESS",
    SET_HOUSE="SET_HOUSE",
    SAVE_HOUSE="SAVE_HOUSE",
    SHOW_TOOL_DATA="SHOW_TOOL_DATA",
    BAD_DESIGN="BAD_DESIGN",
    // TOOL_ENERGY_UPDATE="TOOL_ENERGY_UPDATE",

    PANEL_SELECT = "PANEL_SELECT",
    BATTERY_SELECT="BATTERY_SELECT",
    BATTERY_UNSELECT="BATTERY_UNSELECT",
    BATTERY_INCREMENT="BATTERY_INCREMENT",
    BATTERY_DECREMENT="BATTERY_DECREMENT",
    HOUSEHOLD_UPDATES="HOUSEHOLD_UPDATES",
    INVERTER_SELECT="INVERTER_SELECT",
    INVERTER_UNSELECT="INVERTER_UNSELECT",
    FINANCE_UPDATE="FINANCE_UPDATE",
    SYSTEM_RESERVE="SYSTEM_RESERVE",

    INITIAL_LEAD_FORM_SUBMITED="INITIAL_LEAD_FORM_SUBMITED",
    LEAD_FORM_SUBMITED="LEAD_FORM_SUBMITED",
    NEW_LEAD_CREATED="NEW_LEAD_CREATED",

    SEND_DOCUMENT_TO_CUSTOMER="SEND_DOCUMENT_TO_CUSTOMER",
    FINANCE_AGREEMENT="FINANCE_AGREEMENT",

    LOAN_FINALIZE="LOAN_FINALIZE",
    LOAN_QUALIFY="LOAN_QUALIFY",
    LOAN_DECLINE="LOAN_DECLINE",

    LEASE_FINALIZE="LEASE_FINALIZE",
    LEASE_QUALIFY="LEASE_QUALIFY",
    // LOAN_FINALIZE="LOAN_FINALIZE",

}