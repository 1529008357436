import { useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocompleteProps, { LatLng } from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";
import { OptionTypeBase } from "react-select";
interface PlacesAutocompleteProps extends GooglePlacesAutocompleteProps {
  id?:string;
  name?: string;
  placeholder?: string;
  showSvgIcon?: boolean;
  selectAddress: (address: Address) => void;
  resetInput?: boolean;
  blur: () => void;
  showFocus?: boolean;
  defaultInputValue?: string;
};

export interface Address {
  address: string;
  latlong: LatLng;
  zipcode: string;
  state: string;
  streetAddress: string;
  city: string;
  country: string;
  stateCode: string;
}


const API_KEY = process.env.GOOGLE_ADDRESS_API_KEY;

const PlacesAutocomplete = (props: PlacesAutocompleteProps) => {
  const { showFocus: addFoucsStyle = true, name = "address", showSvgIcon = true, defaultInputValue = '', placeholder = "Enter your address here", resetInput = false } = props;
  const [dyClass, setDyClass] = useState<boolean>(false);
  const [addressVal, setAddressVal] = useState<OptionTypeBase | null>(null);
  const autocompleteRef:any = useRef(null)
  useEffect(() => {
    if (resetInput) {
      setAddressVal(null);
    }
  }, [resetInput])

  useEffect(() => {
    // DON'T REMOVE THIS
    const placeholderDiv = autocompleteRef.current?.querySelector('.project-address__value-container ');
    if (placeholderDiv) {
      placeholderDiv.id = 'project-address'; //Don't change
    }
  }, []);

  const styleOnfun = () => {
    if (!addFoucsStyle) {
      return;
    }
    setDyClass(true);
  };
  const styleOnBlr = () => {
    props.blur();
    setDyClass(false);
  };

  async function addressChangeHandler(event: any) {
    setAddressVal(event);
    let zipcode: any = null;
    let state: any = null;
    let streetAddress: any = '';
    let country: any = null;
    let city: any = null;
    let stateCode: string = '';

    const results = await geocodeByAddress(event.label);
    const place: any = results[0];
    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === "postal_code") {
          zipcode = place.address_components[i].long_name;
        }
        if (
          place.address_components[i].types[j] ===
          "administrative_area_level_1"
        ) {
          state = place.address_components[i].long_name;
          stateCode = place.address_components[i].short_name;
        } else if (
          place.address_components[i].types[j] === "street_number"
        ) {
          streetAddress = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "route") {
          let street = streetAddress?.trim();
          streetAddress = street ? street + " " : ''; 
          streetAddress += `${place.address_components[i].long_name}`;
        } else if (place.address_components[i].types[j] === "locality") {
          city = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "country") {
          country = place.address_components[i].long_name;
        }
      }
    }
    const latlong = await getLatLng(results[0]);
    const selectedAddress: Address = {
      address: event.label,
      zipcode,
      city,
      stateCode,
      country,
      streetAddress,
      state,
      latlong
    };
    props.selectAddress(selectedAddress);

  }

  return (
    <div
      ref={autocompleteRef}
      className={`focus:outline-none relative border border-gray-200 rounded md:rounded-r-none w-full placeholder-current text-bodyColor bg-white
    ${dyClass ? "input-focus" : " "} ${showSvgIcon ? " pl-8 sm:pl-10" : ''}`}
    >
      {showSvgIcon &&
        <span className="flex flex-1 absolute left-0 justify-center items-center -top-px w-10 h-full">
        <svg
          className="w-5 relative left-1.5 opacity-80 z-10"
          viewBox="0 0 25 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 18C11.4122 18 10.3488 17.6774 9.44437 17.0731C8.5399 16.4687 7.83495 15.6098 7.41866 14.6048C7.00238 13.5998 6.89346 12.4939 7.10568 11.427C7.3179 10.3601 7.84173 9.3801 8.61092 8.61092C9.3801 7.84173 10.3601 7.3179 11.427 7.10568C12.4939 6.89346 13.5998 7.00238 14.6048 7.41866C15.6098 7.83495 16.4687 8.5399 17.0731 9.44437C17.6774 10.3488 18 11.4122 18 12.5C17.9983 13.9582 17.4182 15.3561 16.3872 16.3872C15.3561 17.4182 13.9582 17.9983 12.5 18ZM12.5 9.2C11.8473 9.2 11.2093 9.39354 10.6666 9.75615C10.1239 10.1188 9.70097 10.6342 9.4512 11.2371C9.20143 11.8401 9.13608 12.5037 9.26341 13.1438C9.39074 13.7839 9.70504 14.3719 10.1665 14.8335C10.6281 15.295 11.2161 15.6093 11.8562 15.7366C12.4963 15.8639 13.1599 15.7986 13.7629 15.5488C14.3659 15.299 14.8812 14.8761 15.2439 14.3334C15.6065 13.7907 15.8 13.1527 15.8 12.5C15.7991 11.6251 15.4512 10.7862 14.8325 10.1675C14.2138 9.54883 13.3749 9.20088 12.5 9.2Z"
            fill="#7E7E7E"
          />
          <path
            d="M12.5 31L2.91365 19.985C2.78044 19.8196 2.64862 19.6532 2.51819 19.4857C0.880672 17.3841 -0.00395589 14.8171 1.32987e-05 12.1786C1.32987e-05 8.94861 1.31697 5.85095 3.66117 3.56702C6.00538 1.2831 9.1848 0 12.5 0C15.8152 0 18.9946 1.2831 21.3388 3.56702C23.683 5.85095 25 8.94861 25 12.1786C25.004 14.8159 24.1197 17.3818 22.4829 19.4824L22.4818 19.4857C22.4818 19.4857 22.1409 19.9219 22.0898 19.9806L12.5 31ZM4.33183 18.1516C4.3341 18.1516 4.59774 18.4926 4.65796 18.5657L12.5 27.5767L20.3523 18.5535C20.4023 18.4926 20.6682 18.1494 20.6693 18.1483C22.007 16.4312 22.7299 14.3342 22.7273 12.1786C22.7273 9.53588 21.6497 7.00142 19.7318 5.13276C17.8138 3.26409 15.2124 2.21429 12.5 2.21429C9.78756 2.21429 7.18622 3.26409 5.26823 5.13276C3.35025 7.00142 2.27274 9.53588 2.27274 12.1786C2.27035 14.3356 2.99407 16.4338 4.33296 18.1516H4.33183Z"
            fill="#7E7E7E"
          />
        </svg>
        </span>
      }


      <GooglePlacesAutocomplete
        apiKey={API_KEY}
        autocompletionRequest={props?.autocompletionRequest || {
          componentRestrictions: {
            country: "us",
          },
        }}
        selectProps={{
          onChange: addressChangeHandler,
          name,
          placeholder,
          onFocus: styleOnfun,
          onBlur: styleOnBlr,
          innerProps: { id: "landingAddress" },
          ariaAutocomplete: "off",
          instanceId: "instanceId",
          defaultInputValue,
          value: addressVal,
          inputId: "project-address-input",  //Don't change
          classNamePrefix: "project-address" //Don't change
        }}
        aria-message="off"
        aria-error="off"
        aria-autocomplete="off"
      />
    </div>
  );
};

export default PlacesAutocomplete;